import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { UserRole } from '../../../../../../../shared/enum/user-role.enum';
import { SelectedImages } from '../../../../../../../shared/models/selectedImages.model';
import { ProjectDetailPermission } from '../../../../../../../shared/models/projectpermission.model';
import { AssetInfo } from '../../../../../../../shared/models/assetInfo.model';
import { UserDetails } from '../../../../../../../shared/models/userdetails.model';
import { NavigationEnd, Router } from '@angular/router';
import { ImageInfoService } from '../imageinfo.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { UserDataService } from '../../../../../../../shared/services/userdata.service';
import { SidebarService } from '../../../../../sidebar-service/sidebar-service';
import { CompareService } from '../../../../../project-detail/compare/compare.service';
import { UserService } from '../../../../../../../shared/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../../../../../shared/services/snack-bar.service';
import { PaginationService } from '../../../../pagination/pagination.service';
import { LauncherService } from '../../../../../../launcher/launcher.service';
import { SelectedImagesStore } from '../../../../../../../shared/store/selected-images-store';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { LastSelectedImageStore } from '../../../../../../../shared/store/last-selected-image-store';
import { Utils } from '../../../../../../../shared/utils/utils';
import { ImageInfoTabEnum } from '../imageinfo.component';
import { PopupRef } from '../../../../../../../shared/services/popup/popup-ref';
import { FS_POPUP_DATA } from '../../../../../../../shared/services/popup/popup.constants';
import { AlbumType } from '../../../../../../../shared/enum/album-type.enum';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { ArrayUtils } from '../../../../../../../shared/utils/array.utils';
import { ProjectRole } from '../../../../../../../shared/enum/project-role.enum';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';
import {AssetErrorKeys} from '../../../../../../../shared/enum/asset-error-keys.enum';

@Component({
  selector: 'fs-image-notes-dialog',
  templateUrl: './image-notes-dialog.component.html',
  styleUrls: ['./image-notes-dialog.component.scss'],
})
export class ImageNotesDialogComponent
  extends UnsubscriberComponent
  implements OnInit
{
  @Input() isFromLeftMenu: boolean;
  @Output() closeNotePopup = new EventEmitter();
  selectedProjectValue: any;
  albumList: any;
  response: any;
  imageData: object;
  imgInfo: any;
  userRole = UserRole;
  projectRole = ProjectRole;
  selectedImages: SelectedImages[] = [];
  projectDetail: ProjectDetailPermission = new ProjectDetailPermission();
  noteType: any;
  noteData: any;
  submitNoteData: any;
  isImageInfoActive = false;
  imageInfoTab: any = ImageInfoTabEnum.Data;
  distinctUserIds: Array<any> = [];
  isEnlargeView = false;
  projectImageData: AssetInfo = new AssetInfo();
  albumId = 0;
  isExecutiveAlbumView = false;
  hideDataNoteListOption = false;
  isAddNotePopupOpen = false;
  currentUser: UserDetails = new UserDetails();

  projectAuthority: string;
  isForCompareView = false;
  projectImageIds: any = [];
  noOfImages = 0;
  currentPageNumber = 1;
  isFromTopMenu: boolean;
  noteForm = new UntypedFormGroup({
    imageNoteText: new UntypedFormControl(''),
    noteType: new UntypedFormControl(''),
  });

  constructor(
    private router: Router,
    private imageInfoService: ImageInfoService,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private fb: UntypedFormBuilder,
    private modalStatusService: Modalstatusservice,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private userDataService: UserDataService,
    private sidebarService: SidebarService,
    private compareService: CompareService,
    private userService: UserService,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private paginationService: PaginationService,
    private launcherService: LauncherService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogService: DialogService,
    private lastSelectedImageStore: LastSelectedImageStore,
    private popupRef: PopupRef<ImageNotesDialogComponent>,
    @Inject(FS_POPUP_DATA)
    private data: { isFromTopMenu: boolean; projectAuthority: string },
  ) {
    super();
    this.isFromTopMenu = data.isFromTopMenu;
    this.projectAuthority = data.projectAuthority;
  }

  ngOnInit() {
    this.currentUser = this.userService.mergeWithCurrentUser(this.currentUser);
    this.viewProjectService.projectImageIdsGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageIds) => {
        this.projectImageIds = imageIds;
      });

    this.paginationService.paginationIndexGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        if (index > 0) {
          this.currentPageNumber = index;
        } else {
          this.currentPageNumber = 1;
        }
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectDetailPermission) => {
        if (!Utils.isObjectEmpty(projectDetailPermission)) {
          this.projectDetail = projectDetailPermission;
          this.noOfImages = projectDetailPermission.viewSettingDTO.imagePerPage;
        }
      });

    this.launcherService.selectedProjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectValue) => {
        this.selectedProjectValue = Number(projectValue);
      });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.search('enlarge') !== -1 ||
          event.url.search('compare') !== -1
        ) {
          this.isEnlargeView = true;
        } else {
          this.isEnlargeView = false;
        }
        if (event.url.search('compare') !== -1) {
          this.isForCompareView = true;
        } else {
          this.isForCompareView = false;
        }
      }
    });

    this.noteData = {
      imageNoteText: 'Enter Note',
      note: [
        {
          id: 2,
          lableName: 'Public',
          isSelect: false,
          key: 'publicNote',
        },
        {
          id: 1,
          lableName: 'Private',
          isSelect: false,
          key: 'privateNote',
        },
      ],
    };

    this.submitNoteData = {
      imageNoteText: '',
      noteType: 1,
    };

    this.imgInfo = {
      releasedDate: '',
      batch: '',
      name: '',
      status: '',
      imageStatus: '',
      taggedwith: [],
      approvedBy: [],
      publishedBy: [],
      rejectedBy: [],
      albums: [],
      generalAlbums: [],
      customAlbums: [],
      caption: '',
      photographer: '',
      excludedBy: [],
      excluded: false,
    };
  }

  getSelectedImageIDsForCompare() {
    return this.selectedImages.map((selectedImage) => selectedImage.id);
  }

  saveImageNote() {
    const imageNoteText = this.noteForm.get('imageNoteText').value;
    const noteType = this.noteForm.get('noteType').value;
    if (imageNoteText.trim() !== '') {
      const assetIds = [];
      if (!this.isForCompareView) {
        if (this.isEnlargeView) {
          assetIds.push(this.lastSelectedImageStore.getItemsIds());
        } else {
          assetIds.push(this.selectedImagesStore.getItemsIds());
        }
      } else {
        assetIds.push(this.getSelectedImageIDsForCompare());
      }

      const data = {
        assetIds: assetIds[0],
        currentAssetIds: this.isEnlargeView
          ? assetIds[0]
          : Utils.getImageIdsBasedOnPage(
              this.currentPageNumber,
              this.noOfImages,
              this.projectImageIds,
            ),
        type: ProjectRole.ROLE_TALENT === this.projectAuthority ? 2 : noteType,
        value: imageNoteText.trim(),
        albumId: this.getAlbumId(),
        projectId: this.viewProjectService.getProjectId(),
      };
      this.createAssetsNotes(data);
      this.viewProjectService.isAddNoteFromAddNote.next(false);
      if (!this.isFromTopMenu) {
        this.viewProjectService.setViewProjectNoteIconClick(true);
      }
      this.closeWindow();
    } else {
      this.alertCustom(this.translate.instant('view-project.note_empty'));
    }
  }

  getAlbumId() {
    if (
      this.selectedProjectValue === AlbumType.WideEdit ||
      this.selectedProjectValue === AlbumType.SignOff
    ) {
      return this.viewProjectService.getExecutiveAlbumID();
    } else {
      return this.isExecutiveAlbumView ? this.albumId : 0;
    }
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  createAssetsNotes(data) {
    this.loaderService.displayLoader(true);
    this.imageInfoService.callCreateAssetsNotes(data).subscribe(
      (res) => {
        this.projectImageData = this.viewProjectService.mergeWithProjectData(
          this.projectImageData,
        );
        const notes = res;
        const createdBy = [];
        createdBy.push(res[0].assetNoteResponseDTO.createdBy);
        this.userDataService
          .getUserData(this.viewProjectService.getProjectId(), createdBy)
          .subscribe((value) => {
            this.projectImageData.assetInfoDTOList.forEach((imageObj) => {
              notes.forEach((updatedNoteObj) => {
                if (updatedNoteObj.assetId === imageObj.id) {
                  updatedNoteObj.assetNoteResponseDTO['name'] =
                    value[0].firstName + ' ' + value[0].lastName;
                  if (
                    !ArrayUtils.hasDuplicates(
                      imageObj.assetNoteDTOList,
                      (v) => v.id === updatedNoteObj.assetNoteResponseDTO.id,
                    )
                  ) {
                    imageObj.assetNoteDTOList.push(
                      updatedNoteObj.assetNoteResponseDTO,
                    );
                    this.selectedImagesStore.update(imageObj.id, imageObj);
                  }
                }
              });
            });

            if (this.isForCompareView) {
              const comareImages =
                this.compareService.getSelectedImagesCompare();
              Array.from(comareImages).forEach((selectedCOmpareImage) => {
                notes.forEach((updatedNoteObj) => {
                  if (updatedNoteObj.assetId === selectedCOmpareImage[1].id) {
                    updatedNoteObj.assetNoteResponseDTO['name'] =
                      value[0].firstName + ' ' + value[0].lastName;
                    selectedCOmpareImage[1]['assetNoteDTOList'].push(
                      updatedNoteObj.assetNoteResponseDTO,
                    );
                  }
                });
              });
            }

            this.loaderService.displayLoader(false);

            this.submitNoteData = {
              imageNoteText: '',
              noteType: 1,
            };
          }, (error => {
            this.apiErrorHandlerService.getHandler().handle(error);
          }));
      },
      (error) => {
        if (error.error.parameters.message === 'error.validation') {
          this.dialogService
              .openInformationDialog({
                title: this.translate.instant('image-caption.title'),
                message: this.translate.instant('image-caption.error.value.validation'),
              })
        } else {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
          this.submitNoteData = {
            imageNoteText: '',
            noteType: 1,
          };
        }
      },
    );
  }

  closeWindow() {
    this.popupRef.hide();
  }
}
